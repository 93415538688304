<template>
  <div class="">
    <defaultFieldTypes
      :field="field"
      :fieldAttributes="fieldAttributes"
      v-on="$listeners"
      :value="value"
    ></defaultFieldTypes>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";

export default {
  components: {
    defaultFieldTypes,
  },
  props: ["result", "fieldAttributes", "field","value"],
  watch: {
    "result.valueLine.c_type": function (type) {
      let f3 = "nocsConnection";
      if (this.result.additional.source == "inboundConnections") {
        f3 = "inboundConnectionConfiguration";
      }
      var URL =
        "serve.php?f=configuration&f2=" +
        this.result.additional.source +
        "&f3=" +
        f3 +
        "";
      this.frameworkAxiosRequest({
        method: 'POST',
        url: URL,
        dataType: 'json',
        data: {
          function: 'getTypeConfigJSON',
          c_type: type,
          c_subType: this.result.additional.c_subType,
          p: this.result.additional.p,
          responseType: 'ajax',
        },
      })
          .then((response) => {
          console.log(response);
          let clientOpts =
            response.data.result.json.clientsServersList.n_clientId;
          this.$set(
            this.result.fields.n_clientId,
            "associatedOption",
            clientOpts
          );

          let serverOpts =
            response.data.result.json.clientsServersList.n_serverId;
          this.$set(
            this.result.fields.n_serverId,
            "associatedOption",
            serverOpts
          );
          this.$store.commit("dialogResultChanged", this.result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>